exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-present-js": () => import("./../../../src/pages/present.js" /* webpackChunkName: "component---src-pages-present-js" */),
  "component---src-pages-project-aera-js": () => import("./../../../src/pages/project/aera.js" /* webpackChunkName: "component---src-pages-project-aera-js" */),
  "component---src-pages-project-collection-js": () => import("./../../../src/pages/project/collection.js" /* webpackChunkName: "component---src-pages-project-collection-js" */),
  "component---src-pages-project-dialogos-js": () => import("./../../../src/pages/project/dialogos.js" /* webpackChunkName: "component---src-pages-project-dialogos-js" */),
  "component---src-pages-project-flower-js": () => import("./../../../src/pages/project/flower.js" /* webpackChunkName: "component---src-pages-project-flower-js" */),
  "component---src-pages-project-map-js": () => import("./../../../src/pages/project/map.js" /* webpackChunkName: "component---src-pages-project-map-js" */),
  "component---src-pages-project-meaning-js": () => import("./../../../src/pages/project/meaning.js" /* webpackChunkName: "component---src-pages-project-meaning-js" */),
  "component---src-pages-project-messy-js": () => import("./../../../src/pages/project/messy.js" /* webpackChunkName: "component---src-pages-project-messy-js" */),
  "component---src-pages-project-motion-js": () => import("./../../../src/pages/project/motion.js" /* webpackChunkName: "component---src-pages-project-motion-js" */),
  "component---src-pages-project-museum-js": () => import("./../../../src/pages/project/museum.js" /* webpackChunkName: "component---src-pages-project-museum-js" */),
  "component---src-pages-project-ode-js": () => import("./../../../src/pages/project/ode.js" /* webpackChunkName: "component---src-pages-project-ode-js" */),
  "component---src-pages-project-place-js": () => import("./../../../src/pages/project/place.js" /* webpackChunkName: "component---src-pages-project-place-js" */),
  "component---src-pages-project-point-js": () => import("./../../../src/pages/project/point.js" /* webpackChunkName: "component---src-pages-project-point-js" */),
  "component---src-pages-project-scala-js": () => import("./../../../src/pages/project/scala.js" /* webpackChunkName: "component---src-pages-project-scala-js" */),
  "component---src-pages-project-spark-js": () => import("./../../../src/pages/project/spark.js" /* webpackChunkName: "component---src-pages-project-spark-js" */),
  "component---src-pages-project-three-js": () => import("./../../../src/pages/project/three.js" /* webpackChunkName: "component---src-pages-project-three-js" */)
}

